<template>
    <component :is="template">
        <v-responsive width="100%">
            <v-card elevation="0" tile max-width="100%">
                <v-card-title class="headline d-flex d-sm-block justify-center">
                    <span class="py-4 pl-sm-4"><b>주문하기</b></span>
                </v-card-title>
            </v-card>

            <v-card elevation="0" tile max-width="100%">
                <template v-for="cart, index in carts">
                    <v-divider :key="index"/>
                    <pickup-item :key="cart._id" :cart="cart" />
                </template>

                <v-divider/>
            </v-card>

            <v-layout class="mt-4 px-2 py-4 grey lighten-4" wrap>
                <v-flex xs12 md6 class="pa-lg-2 align-selft-stretch">
                    <v-responsive class="overflow-visible px-2">
                        <v-card width="100%" class="mt-2" flat style="position:relative;">
                            <v-btn-toggle v-model="order.paymentMethod" background-color="transparent" class="d-flex fill-height elevation-1" >
                                <v-btn v-if="creditcardEnabled" value="creditcard" class="grow transparent"><v-icon size="20" left>mdi-credit-card-outline</v-icon>신용카드결제</v-btn>
                                <v-btn v-if="mobileEnabled" value="mobile" class="grow transparent"><v-icon size="20" left>mdi-cellphone</v-icon>휴대폰결제</v-btn>
                                <v-btn v-if="pointEnabled" value="point" class="grow transparent"><v-icon size="20" left>mdi-alpha-p-circle</v-icon>포인트결제</v-btn>
                                <v-btn v-if="simpleEnabled" value="simple" class="grow transparent"><v-icon size="20" left>mdi-alpha-p-circle</v-icon>간편결제</v-btn>
                            </v-btn-toggle>
                            <v-overlay v-if="!mobileEnabled" color="transparent" absolute></v-overlay>
                        </v-card>
                        <v-card class="mt-4">
                            <v-card-title>쿠폰을 받을 휴대폰번호</v-card-title>
                            <v-card-text>
                                <template v-if="order.paymentMethod == `creditcard`">
                                    <v-layout>
                                        <v-flex shrink class="px-1 mb-2 mb-md-0"><v-select v-model="order.receiver.phone1" :items="phones" dense outlined hide-details :readonly="!logon || (logon && me?.orderCertType != `비인증`)" @click="!logon || me.orderCertType == `인증` ? $refs.kcpCert.open() : null" style="width: 85px" /></v-flex>
                                        <v-flex class="px-1"><v-text-field v-model="order.receiver.phone2" label="-없이 숫자만 입력" maxlength="8" dense outlined hide-details persistent-placeholder :readonly="!logon || (logon && me?.orderCertType != `비인증`)" @click="!logon || me.orderCertType == `인증` ? $refs.kcpCert.open() : null"/></v-flex>
                                    </v-layout>
                                </template>
                                <template v-if="order.paymentMethod == `mobile`">
                                    <v-btn-toggle class="d-flex px-1 py-2" @change="value => order.telecom = value || order.telecom">
                                        <v-btn class="bg-red" width="25%" :color="order.telecom?.match?.(/^(SKT)$/) ? 'primary' : '' " outlined value="SKT">SKT</v-btn>
                                        <v-btn class="bg-red" width="25%" :color="order.telecom?.match?.(/^(KTF)$/) ? 'primary' : '' " outlined value="KTF">KT</v-btn>
                                        <v-btn class="bg-red" width="25%" :color="order.telecom?.match?.(/^(LGT)$/) ? 'primary' : '' " outlined value="LGT">LG</v-btn>
                                        <v-btn class="bg-red" width="25%" :color="order.telecom?.match?.(/^(CJH|KCT|SKL)$/) ? 'primary' : '' " outlined :value="null" id="eletecom-activator">
                                            <span>{{ order.telecom?.match?.(/(CJH|KCT|SKL)/) ? order.telecom : "알뜰폰" }}</span>
                                            <v-icon right>mdi-chevron-down</v-icon>
                                        </v-btn>

                                        <v-menu offset-y activator="#eletecom-activator">
                                            <v-sheet>
                                                <v-btn text width="100%" :color="order.telecom?.match?.(/^(CJH)$/) ? 'primary' : ''" class="d-block" value="CJH">헬로모바일</v-btn>
                                                <v-btn text width="100%" :color="order.telecom?.match?.(/^(KCT)$/) ? 'primary' : ''" class="d-block" value="KCT">KCT</v-btn>
                                                <v-btn text width="100%" :color="order.telecom?.match?.(/^(SKL)$/) ? 'primary' : ''" class="d-block" value="SKL">SK7mobile</v-btn>
                                            </v-sheet>
                                        </v-menu>
                                    </v-btn-toggle>
                                    <v-layout>
                                        <v-flex shrink class="px-1 mb-2 mb-md-0"><v-select v-model="order.receiver.phone1" :items="phones" dense outlined hide-details :readonly="!logon || (logon && me?.orderCertType != `비인증`)" @click="(!logon ||me.orderCertType == `인증`) ? $refs.kcpCert.open() : null" style="width: 85px" /></v-flex>
                                        <v-flex class="px-1"><v-text-field v-model="order.receiver.phone2" label="-없이 숫자만 입력" maxlength="8" dense outlined hide-details persistent-placeholder :readonly="!logon || (logon && me?.orderCertType != `비인증`)" @click="(!logon || me.orderCertType == `인증`) ? $refs.kcpCert.open() : null"/></v-flex>
                                    </v-layout>
                                    <!-- <v-text-field class="px-1 py-1" v-model="order.authNumber" dense hide-details outlined label="주민번호 앞 6자리" maxlength="6" @change="order.authNumber = order.authNumber?.replace?.(/[^0-9]/g, '')" /> -->
                                </template>
                                <template v-if="order.paymentMethod == `point`">
                                    <v-layout>
                                        <v-flex shrink class="px-1 mb-2 mb-md-0"><v-select v-model="order.receiver.phone1" :items="phones" dense outlined hide-details :readonly="!logon || (logon && me?.orderCertType != `비인증`)" @click="!logon || me.orderCertTyle == `인증` ? $refs.kcpCert.open() : null" style="width: 85px" /></v-flex>
                                        <v-flex class="px-1"><v-text-field v-model="order.receiver.phone2" label="-없이 숫자만 입력" maxlength="8" dense outlined hide-details persistent-placeholder :readonly="!logon || (logon && me?.orderCertType != `비인증`)" @click="!logon || me.orderCertType == `인증` ? $refs.kcpCert.open() : null"/></v-flex>
                                    </v-layout>
                                    <v-layout class="mt-4 align-center">
                                        <v-flex class="px-1"><v-text-field v-model="order.pointAmount" label="포인트 사용" maxlength="8" prepend-inner-icon="mdi-alpha-p-circle" dense outlined hide-details persistent-placeholder :readonly="!logon" /></v-flex>
                                        <v-flex shrink class="px-1 mb-2 mb-md-0"><v-btn color="primary" @click="setPoint">전액사용</v-btn></v-flex>
                                    </v-layout>
                                    <v-layout class="mt-1">
                                        <v-flex shrink class="px-1 mb-2 mb-md-0 caption">* <b>{{ me.name }}</b>님 보유 포인트 <span class="primary--text"><b>{{me.point.format()}}</b></span> <v-icon color="primary" size=20>mdi-alpha-p-circle</v-icon></v-flex>
                                    </v-layout>
                                </template>

                                <div v-if="siteInfo.isVerify === '0'">
                                    <div>* 입력한 번호로 쿠폰 묶음이 전송됩니다. 정확하게 입력해주세요.</div>
                                    <div>* 휴대폰 결제는 입력한 번호로만 결제할 수 있습니다.</div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-responsive>
                </v-flex>
                <v-flex xs12 md6 class="pa-lg-2">
                    <v-card class="mt-2 mx-2">
                        <v-card-text>
                            <v-layout>
                                <v-flex grow-shrink-0>총 상품가격</v-flex>
                                <v-spacer/>
                                <v-flex grow-shrink-0>{{ saleAmount.format() }} 원</v-flex>
                            </v-layout>
                        </v-card-text>

                        <v-divider/>

                        <v-card-text v-show="commissionAmount">
                            <v-layout>
                                <v-flex grow-shrink-0>결제수수료({{ commission }}%)</v-flex>
                                <v-spacer/>
                                <v-flex grow-shrink-0>{{ commissionAmount.format() }} 원</v-flex>
                            </v-layout>
                        </v-card-text>

                        <v-divider/>

                        <v-card-text>
                            <v-layout>
                                <v-flex grow-shrink-0><b>총 결제 예상 금액</b></v-flex>
                                <v-spacer/>
                                <v-flex grow-shrink-0><b>{{ totalAmount.format() }} 원</b></v-flex>
                            </v-layout>
                        </v-card-text>

                        <v-divider/>
                    </v-card>

                    <v-card v-if="!logon" height="50" class="mt-4 mx-2">
                        <v-layout align-center fill-height>
                            <v-flex shrink class="text-center mx-auto txt-left">
                                <agreement-dialog ref="agreementDialog" @agree="isAgreement = true">
                                    <template v-slot:outer="{ terms }">
                                        <v-checkbox v-model="isAgreement" :label="terms?.subject" />
                                    </template>
                                </agreement-dialog>
                            </v-flex>
                            <v-flex shrink class="ml-n8 mr-4">
                                <span @click="$refs.agreementDialog.open()">보기</span>
                            </v-flex>
                        </v-layout>
                    </v-card>

                    <v-card v-if="!logon && order.buyer.phone !== null" height="50" class="mt-4 mx-2">
                        <v-layout align-center fill-height>
                            <v-flex shrink class="text-center mx-auto txt-left">
                                <collection-dialog ref="CollectionDialog" @agree="order.isCollect = true">
                                    <template v-slot:outer="{ terms }">
                                        <v-checkbox v-model="order.isCollect" :label="terms?.subject" />
                                    </template>
                                </collection-dialog>
                            </v-flex>
                            <v-flex shrink class="ml-n8 mr-4">
                                <span @click="$refs.CollectionDialog.open()">보기</span>
                            </v-flex>
                        </v-layout>
                    </v-card>

                    <v-sheet color="transparent" class="mt-4">
                        <v-layout class="mt-4" justify-center fill-height>
                            <v-flex class="px-2">
                                <template v-for="paymentMethod, index in siteInfo.paymentMethods">
                                    <v-btn v-if="order.paymentMethod == paymentMethod" :key="index" width="100%" height="100%" x-large color="primary" elevation="0" class="mb-2" @click="order.paymentGateway = siteInfo.paymentGateways[index], pay()">{{ 1 < siteInfo.paymentMethods.filter(paymentMethod => paymentMethod == order.paymentMethod).length ? siteInfo.paymentGateways[index] : ""}}결제하기</v-btn>
                                </template>

                                <v-btn v-if="order.paymentMethod == `point`" width="100%" height="100%" x-large color="primary" elevation="0" @click="order.paymentGateway = null, pay()">결제하기</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-sheet>
                </v-flex>
            </v-layout>
        </v-responsive>

        <!-- 약관동의 -->

        <!-- 갤럭시아 빌게이트 결제모듈 -->
        <payment-gateway ref="paymentGateway" @complete="complete" />

        <!-- 본인인증 -->
        <kcp-cert ref="kcpCert" @input="onVerify"/>
    </component>
</template>
<script>
import api from "@/api";
import { mapState } from 'vuex';
import { mdiReceipt, mdiBookOpenPageVariant, mdiCreditCardOutline, mdiCellphone } from "@mdi/js";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import PickupItem from "@/components/client/gshop/order/pickup-item.vue";
import AgreementDialog from "@/components/client/gshop/order/agreement-dialog.vue";
import CollectionDialog from "@/components/client/gshop/order/collection-dialog.vue";

// PG
import PaymentGateway from "@/components/client/gshop/payment-gateway.vue";

export default {
    components: {
        KcpCert,
        PickupItem,
        AgreementDialog,
        CollectionDialog,

        // PG
        PaymentGateway,
    },
    data(){
        return {
            mdiReceipt,
            mdiBookOpenPageVariant,
            mdiCreditCardOutline,
            mdiCellphone,

            me: null,

            order: {
                _id: null,
                pickups: [],

                buyer: {
                    _id: null,
                    name: null,
                    username: null,

                    phone: null,
                    phone1: "010",
                    phone2: "",
                    phone3: "",

                    email: null,

                    birthday: null
                },

                receiver: {
                    name: null,

                    phone:  null,
                    phone1: "010",
                    phone2: "",
                    phone3: "",

                    email: null,
                },

                shippingAddress: {
                    postcode: null,
                    address1: null,
                    address2: null
                },

                demand: '',

                pointAmount: 0,
                cashAmount: 0,

                paymentGateway: null,
                paymentMethod: "creditcard",
                isCollect: false,
                telecom: null,
                authNumber: null,
            },

            isAgreement: false,

            carts: [],

            paymentMethods: [],
            phones: ["010", "011", "016", "017", "019"],

            setting: null
        }
    },
    async mounted(){
        await this.init();
    },
    methods: {
        async init(){
            await this.$store.dispatch("getSiteInfo");
            
            // 상품페이지 & 장바구니에서 픽업된 상품 로드
            if(!this.pickup.length) this.$router.go(-1);

            this.order.pickups = this.carts = this.pickup;

            if(this.creditcardEnabled) this.paymentMethods.push("creditcard");
            if(this.mobileEnabled) this.paymentMethods.push("mobile");
            if(this.pointEnabled) this.paymentMethods.push("point");

            this.order.paymentGateway = this.siteInfo.paymentGateway;
            this.order.paymentMethod = this.paymentMethods[0];

            if(this.logon){
                let { me } = await api.v1.me.profile.get();
                let { _id, name, username, phone, email, postcode, address1, address2, birthday } = me;
                let [ phone1, phone2, phone3 ] = phone?.phoneNumberFormat?.()?.split?.('-') || [];

                this.$set(this.$data, "me", me);

                this.$set(this.order, "buyer", {
                    ...this.order.buyer,
                    _id,
                    name,
                    username,
                    phone,
                    phone1, 
                    phone2: phone2 + phone3,
                    email,
                    birthday
                });

                if(me.orderCertType != `비인증`){
                    this.$set(this.order, "receiver", {
                        name,
                        phone1,
                        phone2: phone2 + phone3,
                        birthday
                    });
                }

                this.$set(this.order, "shippingAddress", {
                    postcode,
                    address1,
                    address2
                });
            }
        },

        // 본인인증 완료시, 데이터 바인딩
        onVerify({ name, phone, birthday }){
            let [phone1, phone2, phone3] = String(phone).phoneNumberFormat().split('-');

            this.order.receiver.name = name;
            this.order.receiver.phone1 = phone1;
            this.order.receiver.phone2 = phone2 + phone3;
            this.order.receiver.birthday = birthday;
        },

        validate(){
            try{
                if(!this.pickup.length) this.$router.go(-1);
                if(!this.order.paymentMethod) throw new Error("결제방법을 선택해주세요");
                if(!this.logon && !this.order.receiver.phone) throw new Error("쿠폰 받으시는분의 연락처를 입력해주세요");
                if(this.order.receiver.phone === null) throw new Error("쿠폰 받으시는분의 연락처를 입력해주세요");

                if(!this.logon && !this.isAgreement) throw new Error("구매약관에 동의해주시기 바랍니다");
                if(!this.logon && this.order.receiver.phone !== null && !this.order.isCollect) throw new Error("발송번호 수집동의에 동의해주시기 바랍니다");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },

        async pay(){ 
            if(this.validate()){
                let { order } = await api.v1.gshop.orders.post(this.order);

                switch(order.paymentMethod){
                    case "creditcard": 
                    case "mobile": {
                        this.$refs.paymentGateway.order = order;
                        this.$refs.paymentGateway.pay();

                        break;
                    }
                    case "point": {
                        let { payload } = await api.v1.gshop.orders.pay.post(order);
                        this.complete(payload);
                        break;
                    }
                }
            }
        },
        complete(payload){
            this.$router.push({ path: `/gshop/result`, query: payload })
        },

        setPoint(){
            this.order.pointAmount = (this.totalAmount < this.me.point) ? this.totalAmount : this.me.point;
        }
    },
    computed: {
        siteInfo(){
            return this.$store.state.siteInfo;
        },
        
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // UI
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////

        template(){ return () => import(`@/templates/${this.$theme.pages.shop}`); },

        pickup(){
            return this.$store.state.pickup || [];
        },

        accessToken(){
            return this.$store.state.accessToken;
        },

        logon(){
            return !!this.accessToken;
        },

        creditcardEnabled(){
            return this.siteInfo.paymentMethods.includes('creditcard') && this.order.pickups.find(pickup => pickup?.giftcard?.paymentMethods?.includes?.("creditcard"));
        },

        mobileEnabled(){
            return this.siteInfo.paymentMethods.includes('mobile') && this.order.pickups.find(pickup => pickup?.giftcard?.paymentMethods?.includes?.("mobile"));
        },
        
        simpleEnabled(){
            return this.siteInfo.paymentMethods.includes('simple') && this.order.pickups.find(pickup => pickup?.giftcard?.paymentMethods?.includes?.("simple"));
        },
        pointEnabled(){
            return this.order.pickups.find(pickup => pickup?.giftcard?.paymentMethods?.includes?.("point"));
        },

        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // 금액
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////

        saleAmount(){
            return this.carts.reduce((saleAmount, cart) => saleAmount + cart.salePrice * cart.quantity, 0);
        },

        commission(){
            return +this.carts[0]?.giftcard?.commission?.[this.order.paymentMethod] || 0;
        },

        commissionAmount(){
            if(!this.commission) return 0;
            return this.carts.reduce((commissionAmount, cart) => commissionAmount + Math.floor(cart.salePrice * this.commission / (1000 * this.cutUnit)) * this.cutUnit * 10 * cart.quantity, 0);
        },

        cutUnit(){
            return +this.carts[0]?.giftcard?.commission?.cutUnit || 1;
        },

        totalAmount(){
            return this.saleAmount + this.commissionAmount;
        }
    },
    watch: {
        "order.receiver": {
            deep: true,
            handler(value){
                var { phone1, phone2 } = value;
                value.phone = 10 <= `${phone1}${phone2}`.length ? `${phone1}${phone2}` : null;
                if(!this.logon) this.order.buyer = { ...value };
            }
        },
        async "order.isCollect"() {
            if(!!this.order.isCollect){
                await api.v1.gshop.collection.post({ phone: this.order.buyer.phone, authNumber: this.order.authNumber, telecom: this.order.telecom });
            }
        },
        "order.paymentMethod"(){
            this.order.pointAmount = 0;
            this.order.paymentGateway = this.siteInfo.paymentGateways[0];
        }
    }
}
</script>

<style scoped>
.row + .row {
    margin-top: 0px;
}
.v-number-field >>> input {
    text-align: right !important;
}

.v-application .txt-left {
    margin-left: 0 !important;
    padding-left: 8px;
}

.v-application button.primary--text {
    color: #fff !important;
    background: #e72456 !important;
}
</style>